$(document).ready(function () {
  $("#form_login1").submit(function (e) {
    e.preventDefault();
    var enterYourReg = $("#enterYourReg").val();
    if (enterYourReg >= 1) {
      $(".error").remove();
      $(".search-results-container").css("display", "block");
      $(".search-results-container").show();
      //$("#search-results-paragraph").css("display", "block");
      $(".search-results-table").css("display", "block");
      $(".search-results-button").css("display", "block");
      $("#mobile-search-results-container").css("display", "none");
      $("#mobile-search-results-paragraph").css("display", "none");
      $("#mobileSearchRegResultsButton").css("display", "none");
      //$(".hide-results-angular").css("display", "none");
    }

    /*else if (resObj[0]["Licenseplate2TypeIDResult"][0]["ctmessage"][0]["ctdata"][0]["resultset"][0]["Types"].length > 1) {
            $("#search-results-paragraph").css("display", "block");
    }*/
  });

  //$(".show-elf-card").click(function () {
  // $(".big-card-2").show();
  // $(this).hide();
  // });
  // $(".show-petro-card").click(function () {
  // $(".big-card-3").show();
  //  $(this).hide();
  //});



  $("#enterMake").click(function () {
    // $("#select").slideToggle(500);
    /* Added by Damien */
    $("#main-section-background").toggleClass("addGreyBackground");
    $("#inputAndGoContainer").removeClass("inputfocus");
    $("#Cars-And-Vans-Only").hide();
    $("#go-button").hide();
    $("#right-oil-for-your-vehicle").show();
    $("#vehicle-icons").show();
    // $(".search-results-container").hide();
    $(".search-results-table").hide();
    $(".reg-results-search-buttons").hide();
    /* End of Code added by Damien */
    $("label[for=chooseVehicle]").css("color", "#00c7ff");
    $("#select-vehicle-dropdown").css("border", "solid 4px #00c7ff");
    // Disable Buttons
    $("#select-make-dropdown").attr("disabled", true);
    $("#select-model-dropdown").attr("disabled", true);
    $("#select-type-dropdown").attr("disabled", true);
    $("#search-make-button").attr("disabled", true);
    // $(".search-div").css("padding-bottom", "110px");
    //$('.search-div').css("transition-delay", "0.01s");
    //$("#enterMake").css("box-shadow","0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12)");
  });

  $("#enterYourReg").click(function () {
    var window_width = parseInt($(window).width());
    if (window_width > 767) {
      $("#inputAndGoContainer").addClass("inputfocus");
      $(".go-button").fadeIn(1500);
      $("#Cars-And-Vans-Only").fadeIn(1500);
      $("#enterYourRegContainer").addClass("removeBorder");
      $("#right-oil-for-your-vehicle").hide();
      $("#vehicle-icons").hide();
      $("#select").fadeOut();
      $("#main-section-background").removeClass("addGreyBackground");
    } else {
      //No input focus on Enter Reg for mobile.
    }
  });


  /* For testing only - close results on desktop */
  $("#lottie").click(function () {
    $("#inputAndGoContainer").removeClass("inputfocus");
    $(".go-button").hide();
    $("#Cars-And-Vans-Only").hide();
    $("#enterYourRegContainer").removeClass("removeBorder");
    $("#right-oil-for-your-vehicle").show();
    $("#vehicle-icons").show();
    $(".error").hide();
    $(".search-results-container").hide();
    $("#search-results-paragraph").hide();
    $(".search-results-table").hide();
    $(".search-results-button").hide();
  });

  $("#searchRegButton").click(function () {
    var enterYourReg = $("#enterYourReg").val();

    if (enterYourReg < 1) {
      $("#searchRegButton").hide();
      $(".mobile-error").css("display", "block");
    } else {
      /*else if (resObj[0]["Licenseplate2TypeIDResult"][0]["ctmessage"][0]["ctdata"][0]["resultset"][0]["Types"].length > 1) {
        $("#search-results-paragraph").css("display", "block");
    }*/
      $(".error").css("display", "none");
      //$('.search-div').css("padding-bottom", "200px");
      //$('.search-div').css("transition-delay", "0.6s");
      $("#mobile-search-results-container").css("display", "block");
      //$("#mobile-search-results-paragraph").css("display", "block");
      $("#mobileSearchRegResultsButton").css("display", "block");
      //$("#searchRegButton").hide();
      /*$("#search-results-container").css("display", "none");
      $(".search-results-table").css("display", "none");
      $(".search-results-button").css("display", "none");*/
      //$(".hide-results-angular").css("display", "none");
    }
  });
  /* For testing only - close results on mobile */
  $("#mobileSearchRegResultsButton").click(function () {
    $("#mobile-search-results-container").hide();
    $("#mobile-search-results-paragraph").hide();
    $("#mobileSearchRegResultsButton").hide();
    $("#searchRegButton").show();
  });

  //Shadows for Onload
  $("#inputAndGoContainer").addClass("big-shadow");
  $("#enterMake").addClass("small-shadow");

  // Shadows for Click Enter Make
  $("#enterMake").click(function () {
    $("#inputAndGoContainer").removeClass("big-shadow");
    $("#inputAndGoContainer").addClass("small-shadow");
    $("#enterMake").removeClass("small-shadow");
    $("#enterMake").addClass("big-shadow");
    $("#enterYourRegContainer").removeClass("removeBorder");
    $("#select-vehicle-dropdown").css(
      "box-shadow",
      "0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12)"
    );
    $("#select-make-dropdown").css(
      "box-shadow",
      "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    );
    $("#select-model-dropdown").css(
      "box-shadow",
      "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    );
    $("#select-type-dropdown").css(
      "box-shadow",
      "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    );
    $("#search-make-button").css(
      "box-shadow",
      "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    );
  });

  // Shadows for Click Enter Reg
  $("#enterYourReg").click(function () {
    $("#inputAndGoContainer").removeClass("small-shadow");
    $("#inputAndGoContainer").addClass("big-shadow");
    $("#enterMake").removeClass("big-shadow");
    $("#enterMake").addClass("small-shadow");
  });
});

function FilterVehicleResults(
  inputFieldName,
  SelectItemULName,
  SelectItemLiNames
) {
  var input, filter, ul, li, a, i, selectOptionText, positionOfImgTag;
  input = document.getElementById(inputFieldName);
  filter = input.value.toUpperCase();
  ul = document.getElementById(SelectItemULName);
  li = document.getElementsByClassName(SelectItemLiNames);
  for (i = 1; i < li.length; i++) {
    selectOptionText = document.getElementsByClassName(SelectItemLiNames)[i]
      .innerHTML;
    var positionOfImgTag = selectOptionText.indexOf("<img");
    if (positionOfImgTag == -1) {
      if (selectOptionText.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    } else {
      var removeImgTag = selectOptionText.slice(0, positionOfImgTag);
      if (removeImgTag.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }
}

function ClickedButton(SelectedModalButton, ButtonID) {
  document.getElementById(SelectedModalButton).style.color = "#000";
  ChangeEnterMakeDropdownColors(SelectedModalButton);
}

function ChangeEnterMakeDropdownColors(ModalName) {
  var modal = ModalName;
  if (modal == "select-vehicle-dropdown") {
    // Select Vehicle
    $("label[for=chooseVehicle]").css("color", "#949496");
    $("#select-vehicle-dropdown").css("border", "none");
    $("#select-vehicle-dropdown").css(
      "box-shadow",
      "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    );
    // Select Make
    $("label[for=chooseMake]").css("color", "#00c7ff");
    $("#select-make-dropdown").css("border", "solid 4px #00c7ff");
    $("#select-make-dropdown").css(
      "box-shadow",
      "0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12)"
    );
    // Select Model
    $("label[for=chooseModel]").css("color", "#949496");
    $("#select-model-dropdown").css("border", "none");
    $("#select-model-dropdown").css(
      "box-shadow",
      "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    );
    // Select Type
    $("label[for=chooseType]").css("color", "#949496");
    $("#select-type-dropdown").css("border", "none");
    $("#select-type-dropdown").css(
      "box-shadow",
      "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    );
    // Search Button
    $("#search-make-button").css("background-color", "#787676");
    $("#search-make-button").css("background-image", "none");
    $("#search-make-button").css(
      "box-shadow",
      "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    );
    // Disable Buttons
    $("#select-make-dropdown").attr("disabled", false);
    $("#select-model-dropdown").attr("disabled", true);
    $("#select-type-dropdown").attr("disabled", true);
    $("#search-make-button").attr("disabled", true);
  } else if (modal == "select-make-dropdown") {
    // Select Vehicle
    $("label[for=chooseVehicle]").css("color", "#949496");
    $("#select-vehicle-dropdown").css("border", "none");
    $("#select-vehicle-dropdown").css(
      "box-shadow",
      "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    );
    // Select Make
    $("label[for=chooseMake]").css("color", "#949496");
    $("#select-make-dropdown").css("border", "none");
    $("#select-make-dropdown").css(
      "box-shadow",
      "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    );
    // Select Model
    $("label[for=chooseModel]").css("color", "#00c7ff");
    $("#select-model-dropdown").css("border", "solid 4px #00c7ff");
    $("#select-model-dropdown").css(
      "box-shadow",
      "0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12)"
    );
    // Select Type
    $("label[for=chooseType]").css("color", "#949496");
    $("#select-type-dropdown").css("border", "none");
    $("#select-type-dropdown").css(
      "box-shadow",
      "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    );
    // Search Button
    $("#search-make-button").css("background-color", "#787676");
    $("#search-make-button").css("background-image", "none");
    $("#search-make-button").css(
      "box-shadow",
      "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    );
    // Disable Buttons
    $("#select-make-dropdown").attr("disabled", false);
    $("#select-model-dropdown").attr("disabled", false);
    $("#select-type-dropdown").attr("disabled", true);
    $("#search-make-button").attr("disabled", true);
  } else if (modal == "select-model-dropdown") {
    // Select Vehicle
    $("label[for=chooseVehicle]").css("color", "#949496");
    $("#select-vehicle-dropdown").css("border", "none");
    $("#select-vehicle-dropdown").css(
      "box-shadow",
      "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    );
    // Select Make
    $("label[for=chooseMake]").css("color", "#949496");
    $("#select-make-dropdown").css("border", "none");
    $("#select-make-dropdown").css(
      "box-shadow",
      "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    );
    //Select Model
    $("label[for=chooseModel]").css("color", "#949496");
    $("#select-model-dropdown").css("border", "none");
    $("#select-model-dropdown").css(
      "box-shadow",
      "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    );
    //Select Type
    $("label[for=chooseType]").css("color", "#00c7ff");
    $("#select-type-dropdown").css("border", "solid 4px #00c7ff");
    $("#select-type-dropdown").css(
      "box-shadow",
      "0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12)"
    );
    // Search Button
    $("#search-make-button").css("background-color", "#787676");
    $("#search-make-button").css("background-image", "none");
    $("#select-make-button").css(
      "box-shadow",
      "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    );
    // Disable Buttons
    $("#select-make-dropdown").attr("disabled", false);
    $("#select-model-dropdown").attr("disabled", false);
    $("#select-type-dropdown").attr("disabled", false);
    $("#search-make-button").attr("disabled", true);
  } else if (modal == "select-type-dropdown") {
    // Select Vehicle
    $("label[for=chooseVehicle]").css("color", "#949496");
    $("#select-vehicle-dropdown").css("border", "none");
    $("#select-vehicle-dropdown").css(
      "box-shadow",
      "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    );
    // Select Make
    $("label[for=chooseMake]").css("color", "#949496");
    $("#select-make-dropdown").css("border", "none");
    $("#select-make-dropdown").css(
      "box-shadow",
      "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    );
    //Select Model
    $("label[for=chooseModel]").css("color", "#949496");
    $("#select-model-dropdown").css("border", "none");
    $("#select-model-dropdown").css(
      "box-shadow",
      "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    );
    //Select Type
    $("label[for=chooseType]").css("color", "#949496");
    $("#select-type-dropdown").css("border", "none");
    $("#select-type-dropdown").css(
      "box-shadow",
      "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)"
    );
    $("#search-make-button").css(
      "backgroundImage",
      "linear-gradient(to right, #F8B12E , #E47F0D)"
    );
    $("#search-make-button").css(
      "box-shadow",
      "0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12)"
    );
    // Disable Buttons
    $("#select-make-dropdown").attr("disabled", false);
    $("#select-model-dropdown").attr("disabled", false);
    $("#select-type-dropdown").attr("disabled", false);
    $("#search-make-button").attr("disabled", false);
  }

}

$(document).ready(function () {
	$('.results-page-enter-make').click(function () {
		alert("hi");
	});
});







$(document).ready(function () {
  // Display Search Bar
  $('#main-menu-search-icon').click(function () {
    $('.main-menu-options').hide();
    $('#search-bar-container').fadeIn(1500);
  });
  // Hide Search Bar
  $('#search-bar-close-icon').click(function () {
    $('#search-bar-container').hide();
    $('.main-menu-options').fadeIn(1500);
    $('.navbar-toggler-icon').fadeIn(1500);
    $('#finol-logo').removeClass('small-finol-logo');
  });
  // Search Function when clicking submit button
  $('#search-bar-button').click(function () {
    var search_bar_input = $('#search-bar').val();
    window.location.href = "https://www.finol.ie/?s=" + search_bar_input;
  });
  // Search Function when pressing enter button
  $('#search-bar').keyup(function (e) {
    if (e.keyCode == 13) {
      var search_bar_input = $('#search-bar').val();
      window.location.href = "https://www.finol.ie/?s=" + search_bar_input;
    }
  });
  // Mobile Search Bar
  $('#mobile-search-icon').click(function () {
    $('.main-menu-options').hide();
    $('#search-bar-container').fadeIn(1500);
    $('#finol-logo').addClass('small-finol-logo');
    $('.navbar-toggler-icon').css('display', 'none');
    $('.navbar').css('height', '100px');
    $('#search-bar-container').css('z-index', '99');
    $('.main-menu').removeClass('show');
  });
  // Show Main Menu on Mobile
  $(".navbar-toggler-icon").click(function () {
    $("#main-navigation").toggle(500);

  });

});
